import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    constructor() { }

    Alert(message: string) {
        this.showSimpleAlert(message)
    }

    Success(message: string, title: string = 'Success') {
        this.showAlert('success', title, message)
    }

    Error(message: string, title: string = 'Error') {
        this.showAlert('error', title, message)
    }

    Info(message: string, title: string = 'Info') {
        this.showAlert('info', title, message)
    }

    Warning(message: string, title: string = 'Warning') {
        this.showAlert('warning', title, message)
    }

    private showAlert(icon: SweetAlertIcon, title: string, message: string): void {
        Swal.fire({
            icon: icon,
            title: title,
            text: message,
            // showConfirmButton: false,
            // timer: 1500,
            showCloseButton: true,
        })
    }

    private showSimpleAlert(message: string): void {
        Swal.fire({
            text: message,
            showConfirmButton: false,
            timer: 1000,
            position: 'top',
            showCloseButton: false,
            // toast: true,
            timerProgressBar: false,
        })
    }

    InfoWithCallback(message: string, title: string = 'Info', callback: Function, callback_onCancel?: Function) {
        this.showAlertWithCallback('info', title, message, callback, callback_onCancel)
    }

    WorningWithCallback(message: string, title: string = 'Warning', callback: Function, callback_onCancel?: Function) {
        this.showAlertWithCallback('warning', title, message, callback, callback_onCancel)
    }

    private showAlertWithCallback(icon: SweetAlertIcon, title: string = 'Do you want to save the changes?', message: string, callbackFunction: Function, callbackFunction_onCancel?: Function): void {
        Swal.fire({
            icon: icon,
            title: title,
            text: message,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No"
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                callbackFunction()
                // Swal.fire("Completed!", "", "success");
            } else if (result.isDenied) {
                if (callbackFunction_onCancel) {
                    callbackFunction_onCancel()
                }
                // Swal.fire("Cancelled!", "", "info");
            }
        });
    }

    // ==== Usage ===== 
    // If you need to perform task at that class context
    // (ex:- if your callback contains this keyword)
    // this.notificationService.InfoWithCallback(`Are you sure want to save`, "Please confirm!", () => this.myfunction(param1, param2))

    // if your callback have not bind to special context
    // this.notificationService.InfoWithCallback(`Are you sure want to save`, "Please confirm!", this.myfunction, param1, param2)

}

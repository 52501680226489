import { Injectable, OnInit } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService) { }

  getAccessToken() {
    return this.authService.getIsAuthenticated().accessToken ?? ""
  }
  getRefreshToken() {
    return this.authService.getIsAuthenticated().refreshToken ?? ""
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.getAccessToken()
    let refreshToken = this.getRefreshToken()
    // console.log("Bearer token added by interceptor: ", token)

    // Clone the request and add the Authorization header with the bearer token
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    // }

    // Check if the request URL is not for the login endpoint
    if (request.url.includes('/auth/refresh') && refreshToken) {
      // Clone the request and add the Authorization header with the bearer token
      console.log("attached refresh token");
      
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
    } else if (token) {
      console.log("attached access token");

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    // Continue with the modified request
    return next.handle(request);
  }
}

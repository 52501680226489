import { Injectable } from "@angular/core";
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class FileExporter {


  exportExcelFile(data: any[], fileName: string) {
    // const data = [
    //   ['Name', 'Age', 'Email'],
    //   ['John Doe', 30, 'john.doe@example.com'],
    //   ['Jane Smith', 25, 'jane.smith@example.com']
    // ];

    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `${fileName}.xlsx`);

  }

  getCurrentDate() {
    let date = new Date()
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
  }


}
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LoginComponent } from './core';
import { Observable, Subscription } from 'rxjs';
// import { AuthStateSession } from './session-servicesll/auth.state.session.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// import { AuthAccessTokenSession } from './session-servicesll/auth.access-key.session.service';
import { LoadingService } from './core/components/loading/loading.service';
import { AuthService } from './auth.service';
import { SessionStorageService } from './core/util/session-storage.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading: boolean = false; // Add the loading property
  currentUrl = ''
  parentRoute = ''
  childRoute = ''

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private sessionStorageService: SessionStorageService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loading = false;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;
        this.currentUrl = currentRoute

        if (currentRoute === '/login') {
          this.isLoginPage = true
        }
        else {
          this.isLoginPage = false
        }
      }
    });

    this.isLoginPage = true

    this.loadingService.isLoading.subscribe((isLoading) => {
      setTimeout(() => {
        this.loading = isLoading;
        this.cdr.detectChanges();  // Manually trigger change detection
      }, 10);
    });
  }



  // pdfResponseUrl = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'
  pdfResponseUrl = '../assets/reports/report.pdf'

  isUserAuthenticated: string = 'true';
  isLoginPage: boolean = true;

  logOutUser() {
    this.authService.logout()
  }

  isMenuOpened: boolean = false
  openMenu = () => {
    this.isMenuOpened = !this.isMenuOpened
  }

  closeTopMenu() {
    if (this.isMenuOpened) {
      this.isMenuOpened = !this.isMenuOpened
    }
  }

  activateChildRoute(childRoute: string) {
    let parentRoute = this.getBaseRoute()
    let fullChildRoute = 'unauthorized'
    if (parentRoute === '') {
      fullChildRoute = 'unauthorized'
    } else {
      fullChildRoute = `${parentRoute}/${childRoute}`
    }
    this.router.navigate([`${fullChildRoute}`]);
  }

  private getBaseRoute(): string {
    const segments = this.currentUrl.split('/');
    if (segments.length >= 2) {
      const baseRoute = segments[1]; // Index 0 is an empty string due to leading '/'
      return baseRoute
    } else {
      return ''
    }
  }

  isUserMenuOpened = false
  openUserMenu() {
    this.isUserMenuOpened = true
  }
  closeUserMenu() {
    this.isUserMenuOpened = false
  }
}

// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { GINSave, GINSearchResult, JasperAuthStorageType } from '../types';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class GinService  extends APIRequest{

    constructor(http: HttpClient) {
        super(http, APIRequestResources.SavindaAPI);
      }

    findGINs(params: any): Observable<APIResponse<GINSearchResult[]>> {
        const options: APIRequestOptions = {
            params: params,
            endpoint: 'gin/find', // Using the 'find' endpoint to get a specific book
        };
        return this.get<GINSearchResult[]>(options);
    }

    getGIN(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'gin',
            suffix: id
        };
        return this.get<any>(options);
    }    
    
    getPendingGINs(): Observable<APIResponse<any[]>> {
        const options: APIRequestOptions = {
            endpoint: 'gin/status/pending'
        };
        return this.get<any[]>(options);
    }

    updateGINStatue(id: string, statue: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint: 'gin/status',
            suffix:id,
        };
        return this.patch<any>(statue, options);
    }


    createGIN(gin: GINSave): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:"gin"
        };
        return this.post<GINSave>(gin, options);
    }

    getGinReport(id: string): Observable<any> {
        const options: APIRequestOptions = {
            suffix: id,
            endpoint: 'gin/report'
        };

        let sessionStorageData = sessionStorage.getItem('jspAuthData');
        let jasperAuthData: JasperAuthStorageType = {
            JSESSIONID: null,
            userLocale: null
        }
        if (sessionStorageData) {
            jasperAuthData = JSON.parse(sessionStorageData)
        }
        return this.getBlob(options, jasperAuthData.JSESSIONID ?? '');
    }
}
// auth.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { JasperAuthStorageType, UserAuthStorageType } from './core/types';
import { Router } from '@angular/router';
import { SessionStorageService } from './core/util/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    initialUser: UserAuthStorageType = {
        accessToken: null,
        refreshToken: null,
    }
    isRefreshingToken = false
    constructor(
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) {
        console.log("===== Auth Service Invoked =====");
        this.directUserOnPageLoad()
    }

    getIsAuthenticated(): UserAuthStorageType {
        let sessionStorageData: UserAuthStorageType = this.sessionStorageService.getUserToken() ?? this.initialUser
        return sessionStorageData
    }


    setJasperAuthData(jasperAuthData: JasperAuthStorageType) {
        let jspAuth: JasperAuthStorageType = {
            JSESSIONID: jasperAuthData?.JSESSIONID,
            userLocale: jasperAuthData?.userLocale
        }
        sessionStorage.setItem('jspAuthData', JSON.stringify(jspAuth));
    }

    directUserOnPageLoad(): void {
        let userAuthLevel = this.getIsAuthenticated().refreshToken
        if (!userAuthLevel) {
            this.router.navigate(['/login']);
        }
    }

    login(): void {
        let isUserExist = this.getIsAuthenticated().refreshToken
        if (isUserExist) {
            this.router.navigate(['/dashboard']);
        } else {
            this.router.navigate(['/login']);
        }
    }

    logout(): void {
        // Remove the token and update the authentication status
        sessionStorage.removeItem("jspAuthData")
        this.sessionStorageService.removePermissions()
        this.sessionStorageService.removeUserToken()
        this.sessionStorageService.removeUserRoles()
        this.router.navigate(['/login'], { replaceUrl: true });
    }

    private tokenRefreshedSubject: BehaviorSubject<void> = new BehaviorSubject<any>(null);
    public readonly tokenRefreshed: Observable<void> = this.tokenRefreshedSubject.asObservable();
    public refreshTokenCompleted(): void {
        this.tokenRefreshedSubject.next();
    }
}

// book.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIRequestOptions, APIResponse } from '../interfaces';
import { InventoryTypeSearchResult, Invoice } from '../types';
import { APIRequestResources } from '../enums';
import { APIRequest } from '../classes';

@Injectable({
    providedIn: 'root',
})
export class InvoiceService extends APIRequest {
    constructor(http: HttpClient)  {
        super(http, APIRequestResources.SavindaAPI); 
    }

    saveInvoice(invoice: Invoice): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'invoice'
        };
        return this.post<any>(invoice,options);
    }    
    
    getInvoiceById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'invoice',
            suffix:id
        };
        return this.get<any>(options);
    }    
    
    find(searchParams: any): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'invoice/find',
            params: searchParams
        };
        return this.get<any>(options);
    }

    getInvoiceTypeById(id: string): Observable<APIResponse<any>> {
        const options: APIRequestOptions = {
            endpoint:'invoice-type',
            suffix:id
        };
        return this.get<any>(options);
    } 
}
// Example service
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'src/app/core/util/session-storage.service';

@Injectable({
    providedIn: 'root',
})
export class NavArray {
    constructor(
        private sessionStorageService: SessionStorageService
    ) {
        // this.filterAllowedRoutes()
        this.activateAllRoutes()
    }

    private uiUrlPaths: any[] = [
        {
            enable: false,
            routerLink: 'dashboard',
            childrenRoutes: [],
        },
        {
            enable: false,
            routerLink: 'reports',
            childrenRoutes: [],
            // childrenRoutes: [
            //     {
            //         enable: false,
            //         routerLink: 'reports/home',
            //     },
            // ],
        },
        {
            enable: false,
            routerLink: 'inventory',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'inventory/search',
                },
                {
                    enable: false,
                    routerLink: 'inventory/create',
                },
                {
                    enable: false,
                    routerLink: 'inventory/edit',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'supplier',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'supplier/search',
                },
                {
                    enable: false,
                    routerLink: 'supplier/create',
                },
                {
                    enable: false,
                    routerLink: 'supplier/edit',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'customer',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'customer/search',
                },
                {
                    enable: false,
                    routerLink: 'customer/create',
                },
                {
                    enable: false,
                    routerLink: 'customer/edit',
                },
                {
                    enable: false,
                    routerLink: 'customer/address',
                },
                {
                    enable: false,
                    routerLink: 'customer/approve',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'purchase-order',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'purchase-order/search',
                },
                {
                    enable: false,
                    routerLink: 'purchase-order/create',
                },
                {
                    enable: false,
                    routerLink: 'purchase-order/edit',
                }, {
                    enable: false,
                    routerLink: 'purchase-order/approve',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'grn',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'grn/search',
                },
                {
                    enable: false,
                    routerLink: 'grn/create',
                },
                {
                    enable: false,
                    routerLink: 'grn/edit',
                },
                {
                    enable: false,
                    routerLink: 'srn/create',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'gin',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'gin/search',
                },
                {
                    enable: false,
                    routerLink: 'gin/create',
                },
                {
                    enable: false,
                    routerLink: 'gin/approve',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'mrn',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'mrn/search',
                },
                {
                    enable: false,
                    routerLink: 'mrn/create',
                },
                // {
                //     enable: false,
                //     routerLink: 'mrn/approve',
                // },
            ],
        },
        {
            enable: false,
            routerLink: 'employee',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'employee/search',
                },
                {
                    enable: false,
                    routerLink: 'employee/create',
                },
                {
                    enable: false,
                    routerLink: 'employee/edit',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'employee-category',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'employee-category/search',
                },
                {
                    enable: false,
                    routerLink: 'employee-category/create',
                },
                {
                    enable: false,
                    routerLink: 'employee-category/edit',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'sales-inquery',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'sales-inquery/search',
                },
                {
                    enable: false,
                    routerLink: 'sales-inquery/create',
                },
                {
                    enable: false,
                    routerLink: 'sales-inquery/view',
                },
                {
                    enable: false,
                    routerLink: 'sales-inquery/duplicate'
                },
                {
                    enable: false,
                    routerLink: 'sales-inquery/edit',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'product-development',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'product-development/search',
                },
                {
                    enable: false,
                    routerLink: 'product-development/create',
                },
                {
                    enable: false,
                    routerLink: 'product-development/edit',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'quotation',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'quotation/search',
                },
                {
                    enable: false,
                    routerLink: 'quotation/create',
                },
                {
                    enable: false,
                    routerLink: 'quotation/view',
                },
                {
                    enable: false,
                    routerLink: 'quotation/edit',
                },
                {
                    enable: false,
                    routerLink: 'quotation/approve',
                },
                {
                    enable: false,
                    routerLink: 'quotation/approve/quotation-view',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'sales-order',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'sales-order/search',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/create',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/edit',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/sales-order-item',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/search-sales-order-item',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/npd',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/approve',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/approve',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/process-artwork',
                },
                {
                    enable: false,
                    routerLink: 'sales-order/add-bom',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'npd',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'npd/search',
                },
                {
                    enable: false,
                    routerLink: 'npd/create',
                },
                {
                    enable: false,
                    routerLink: 'npd/edit',
                },
                {
                    enable: false,
                    routerLink: 'npd/approve',
                },
                {
                    enable: false,
                    routerLink: 'npd/bom',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'packing',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'packing/search',
                },
                {
                    enable: false,
                    routerLink: 'packing/create',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'dispatch',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'dispatch/search',
                },
                {
                    enable: false,
                    routerLink: 'dispatch/create',
                },
                {
                    enable: false,
                    routerLink: 'dispatch/return',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'invoice',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'invoice/search',
                },
                {
                    enable: false,
                    routerLink: 'invoice/create',
                },
            ],
        },
        {
            enable: false,
            routerLink: 'artwork',
            childrenRoutes: [
                {
                    enable: false,
                    routerLink: 'artwork/search',
                },
                {
                    enable: false,
                    routerLink: 'artwork/create',
                },
            ],
        },
    ];

    getUiUrlPaths() {
        return this.uiUrlPaths
    }


    private childNavIcons = {
        search: "M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z",
        create: "M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM200 344V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z",
        approve: "M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z",
        address: "M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z",
        artwork: "M512 256c0 .9 0 1.8 0 2.7c-.4 36.5-33.6 61.3-70.1 61.3H344c-26.5 0-48 21.5-48 48c0 3.4 .4 6.7 1 9.9c2.1 10.2 6.5 20 10.8 29.9c6.1 13.8 12.1 27.5 12.1 42c0 31.8-21.6 60.7-53.4 62c-3.5 .1-7 .2-10.6 .2C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z",
        inventory: "M248 0H208c-26.5 0-48 21.5-48 48V160c0 35.3 28.7 64 64 64H352c35.3 0 64-28.7 64-64V48c0-26.5-21.5-48-48-48H328V80c0 8.8-7.2 16-16 16H264c-8.8 0-16-7.2-16-16V0zM64 256c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H224c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H184v80c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V256H64zM352 512H512c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H472v80c0 8.8-7.2 16-16 16H408c-8.8 0-16-7.2-16-16V256H352c-15 0-28.8 5.1-39.7 13.8c4.9 10.4 7.7 22 7.7 34.2V464c0 12.2-2.8 23.8-7.7 34.2C323.2 506.9 337 512 352 512z",
        return: "M125.7 160l50.3 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L48 224c-17.7 0-32-14.3-32-32L16 64c0-17.7 14.3-32 32-32s32 14.3 32 32l0 51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z",
    }

    private navigationItems = [
        { // dashboard
            enable: true,
            routerName: 'dashboard',
            routerLink: 'dashboard',
            icon: "M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z",
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: []
        },
        { // reports
            enable: false,
            routerName: 'reports',
            routerLink: 'reports',
            icon: 'M0 96C0 60.7 28.7 32 64 32l448 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM128 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm32-128a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24l224 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-224 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l224 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-224 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l224 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-224 0z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: []
            // childrenRoutes: [
            //     {
            //         enable: false,
            //         routerName: 'home',
            //         routerLink: 'reports/home',
            //         icon: this.childNavIcons.search,
            //         pre_navBreackPoint: false,
            //         post_navBreackPoint: false,
            //     },
            // ]
        },
        { // inventory
            enable: false,
            routerName: 'inventory',
            routerLink: 'inventory',
            icon: 'M248 0H208c-26.5 0-48 21.5-48 48V160c0 35.3 28.7 64 64 64H352c35.3 0 64-28.7 64-64V48c0-26.5-21.5-48-48-48H328V80c0 8.8-7.2 16-16 16H264c-8.8 0-16-7.2-16-16V0zM64 256c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H224c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H184v80c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V256H64zM352 512H512c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H472v80c0 8.8-7.2 16-16 16H408c-8.8 0-16-7.2-16-16V256H352c-15 0-28.8 5.1-39.7 13.8c4.9 10.4 7.7 22 7.7 34.2V464c0 12.2-2.8 23.8-7.7 34.2C323.2 506.9 337 512 352 512z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'inventory/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'inventory/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // supplier
            enable: false,
            routerName: 'supplier',
            routerLink: 'supplier',
            icon: 'M326.7 403.7c-22.1 8-45.9 12.3-70.7 12.3s-48.7-4.4-70.7-12.3c-.3-.1-.5-.2-.8-.3c-30-11-56.8-28.7-78.6-51.4C70 314.6 48 263.9 48 208C48 93.1 141.1 0 256 0S464 93.1 464 208c0 55.9-22 106.6-57.9 144c-1 1-2 2.1-3 3.1c-21.4 21.4-47.4 38.1-76.3 48.6zM256 91.9c-11.1 0-20.1 9-20.1 20.1v6c-5.6 1.2-10.9 2.9-15.9 5.1c-15 6.8-27.9 19.4-31.1 37.7c-1.8 10.2-.8 20 3.4 29c4.2 8.8 10.7 15 17.3 19.5c11.6 7.9 26.9 12.5 38.6 16l2.2 .7c13.9 4.2 23.4 7.4 29.3 11.7c2.5 1.8 3.4 3.2 3.7 4c.3 .8 .9 2.6 .2 6.7c-.6 3.5-2.5 6.4-8 8.8c-6.1 2.6-16 3.9-28.8 1.9c-6-1-16.7-4.6-26.2-7.9l0 0 0 0 0 0c-2.2-.7-4.3-1.5-6.4-2.1c-10.5-3.5-21.8 2.2-25.3 12.7s2.2 21.8 12.7 25.3c1.2 .4 2.7 .9 4.4 1.5c7.9 2.7 20.3 6.9 29.8 9.1V304c0 11.1 9 20.1 20.1 20.1s20.1-9 20.1-20.1v-5.5c5.3-1 10.5-2.5 15.4-4.6c15.7-6.7 28.4-19.7 31.6-38.7c1.8-10.4 1-20.3-3-29.4c-3.9-9-10.2-15.6-16.9-20.5c-12.2-8.8-28.3-13.7-40.4-17.4l-.8-.2c-14.2-4.3-23.8-7.3-29.9-11.4c-2.6-1.8-3.4-3-3.6-3.5c-.2-.3-.7-1.6-.1-5c.3-1.9 1.9-5.2 8.2-8.1c6.4-2.9 16.4-4.5 28.6-2.6c4.3 .7 17.9 3.3 21.7 4.3c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-4.4-1.2-14.4-3.2-21-4.4V112c0-11.1-9-20.1-20.1-20.1zM48 352H64c19.5 25.9 44 47.7 72.2 64H64v32H256 448V416H375.8c28.2-16.3 52.8-38.1 72.2-64h16c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V400c0-26.5 21.5-48 48-48z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'supplier/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'supplier/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // customer
            enable: false,
            routerName: 'customer',
            routerLink: 'customer',
            icon: 'M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164 152v13.9c7.5 1.2 14.6 2.9 21.1 4.7c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-11-2.9-21.6-5-31.2-5.2c-7.9-.1-16 1.8-21.5 5c-4.8 2.8-6.2 5.6-6.2 9.3c0 1.8 .1 3.5 5.3 6.7c6.3 3.8 15.5 6.7 28.3 10.5l.7 .2c11.2 3.4 25.6 7.7 37.1 15c12.9 8.1 24.3 21.3 24.6 41.6c.3 20.9-10.5 36.1-24.8 45c-7.2 4.5-15.2 7.3-23.2 9V360c0 11-9 20-20 20s-20-9-20-20V345.4c-10.3-2.2-20-5.5-28.2-8.4l0 0 0 0c-2.1-.7-4.1-1.4-6.1-2.1c-10.5-3.5-16.1-14.8-12.6-25.3s14.8-16.1 25.3-12.6c2.5 .8 4.9 1.7 7.2 2.4c13.6 4.6 24 8.1 35.1 8.5c8.6 .3 16.5-1.6 21.4-4.7c4.1-2.5 6-5.5 5.9-10.5c0-2.9-.8-5-5.9-8.2c-6.3-4-15.4-6.9-28-10.7l-1.7-.5c-10.9-3.3-24.6-7.4-35.6-14c-12.7-7.7-24.6-20.5-24.7-40.7c-.1-21.1 11.8-35.7 25.8-43.9c6.9-4.1 14.5-6.8 22.2-8.5V152c0-11 9-20 20-20s20 9 20 20z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'customer/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'customer/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'address',
                    routerLink: 'customer/address',
                    icon: this.childNavIcons.address,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'approve',
                    routerLink: 'customer/approve',
                    icon: this.childNavIcons.approve,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // purchase
            enable: false,
            routerName: 'purchase',
            routerLink: 'purchase-order',
            icon: 'M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48H69.5c3.8 0 7.1 2.7 7.9 6.5l51.6 271c6.5 34 36.2 58.5 70.7 58.5H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H199.7c-11.5 0-21.4-8.2-23.6-19.5L170.7 288H459.2c32.6 0 61.1-21.8 69.5-53.3l41-152.3C576.6 57 557.4 32 531.1 32H360V134.1l23-23c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-64 64c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l23 23V32H120.1C111 12.8 91.6 0 69.5 0H24zM176 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm336-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'purchase-order/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'purchase-order/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'approve',
                    routerLink: 'purchase-order/approve',
                    icon: this.childNavIcons.approve,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // grn
            enable: false,
            routerName: 'grn',
            routerLink: 'grn',
            icon: 'M544 416L32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32l512 0c17.7 0 32-14.3 32-32s-14.3-32-32-32zm22.6-137.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L480 274.7 480 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 210.7-41.4-41.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0l96-96zm-320-45.3c-12.5-12.5-32.8-12.5-45.3 0L160 274.7 160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 210.7L54.6 233.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'grn/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                // {
                //     enable: false,
                //     routerName: 'create',
                //     routerLink: 'grn/create',
                //     icon: this.childNavIcons.create,
                //     pre_navBreackPoint: false,
                //     post_navBreackPoint: false,
                // },
            ]
        },
        { // gin
            enable: false,
            routerName: 'gin',
            routerLink: 'gin',
            icon: 'M32 96l512 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 32C14.3 32 0 46.3 0 64S14.3 96 32 96zM9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L96 237.3 96 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-210.7 41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0l-96 96zm320 45.3c12.5 12.5 32.8 12.5 45.3 0L416 237.3 416 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-210.7 41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'gin/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'gin/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'approve',
                    routerLink: 'gin/approve',
                    icon: this.childNavIcons.approve,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // mrn
            enable: false,
            routerName: 'mrn',
            routerLink: 'mrn',
            icon: 'M352 224H305.5c-45 0-81.5 36.5-81.5 81.5c0 22.3 10.3 34.3 19.2 40.5c6.8 4.7 12.8 12 12.8 20.3c0 9.8-8 17.8-17.8 17.8h-2.5c-2.4 0-4.8-.4-7.1-1.4C210.8 374.8 128 333.4 128 240c0-79.5 64.5-144 144-144h80V34.7C352 15.5 367.5 0 386.7 0c8.6 0 16.8 3.2 23.2 8.9L548.1 133.3c7.6 6.8 11.9 16.5 11.9 26.7s-4.3 19.9-11.9 26.7l-139 125.1c-5.9 5.3-13.5 8.2-21.4 8.2H384c-17.7 0-32-14.3-32-32V224zM80 96c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H400c8.8 0 16-7.2 16-16V384c0-17.7 14.3-32 32-32s32 14.3 32 32v48c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V112C0 67.8 35.8 32 80 32h48c17.7 0 32 14.3 32 32s-14.3 32-32 32H80z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'mrn/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'mrn/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                // {
                //     enable: false,
                //     routerName: 'approve',
                //     routerLink: 'gin/approve',
                //     icon: this.childNavIcons.approve,
                //     pre_navBreackPoint: false,
                //     post_navBreackPoint: false,
                // },
            ]
        },
        { // employee
            enable: false,
            routerName: 'employee',
            routerLink: 'employee',
            icon: 'M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'employee/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'employee/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // employee-category
            enable: false,
            routerName: 'employee category',
            routerLink: 'employee-category',
            icon: 'M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H544c53 0 96-43 96-96V96c0-53-43-96-96-96H96zM64 96c0-17.7 14.3-32 32-32H544c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V96zm159.8 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 309.3c0 14.7 11.9 26.7 26.7 26.7h56.1c8-34.1 32.8-61.7 65.2-73.6c-7.5-4.1-16.2-6.4-25.3-6.4H149.3C119.9 256 96 279.9 96 309.3zM461.2 336h56.1c14.7 0 26.7-11.9 26.7-26.7c0-29.5-23.9-53.3-53.3-53.3H421.3c-9.2 0-17.8 2.3-25.3 6.4c32.4 11.9 57.2 39.5 65.2 73.6zM372 289c-3.9-.7-7.9-1-12-1H280c-4.1 0-8.1 .3-12 1c-26 4.4-47.3 22.7-55.9 47c-2.7 7.5-4.1 15.6-4.1 24c0 13.3 10.7 24 24 24H408c13.3 0 24-10.7 24-24c0-8.4-1.4-16.5-4.1-24c-8.6-24.3-29.9-42.6-55.9-47zM512 176a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM320 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                // {
                //     routerName: 'search',
                //     routerLink: 'employee-category/search',
                //     icon: this.childNavIcons.search,
                //     pre_navBreackPoint: false,
                //     post_navBreackPoint: false,
                // },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'employee-category/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // sales-inquery
            enable: false,
            routerName: 'sales inquery',
            routerLink: 'sales-inquery',
            icon: 'M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zm20-312v13.9c7.5 1.2 14.6 2.9 21.1 4.7c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-11-2.9-21.6-5-31.2-5.2c-7.9-.1-16 1.8-21.5 5c-4.8 2.8-6.2 5.6-6.2 9.3c0 1.8 .1 3.5 5.3 6.7c6.3 3.8 15.5 6.7 28.3 10.5l.7 .2c11.2 3.4 25.6 7.7 37.1 15c12.9 8.1 24.3 21.3 24.6 41.6c.3 20.9-10.5 36.1-24.8 45c-7.2 4.5-15.2 7.3-23.2 9V344c0 11-9 20-20 20s-20-9-20-20V329.4c-10.3-2.2-20-5.5-28.2-8.4l0 0 0 0c-2.1-.7-4.1-1.4-6.1-2.1c-10.5-3.5-16.1-14.8-12.6-25.3s14.8-16.1 25.3-12.6c2.5 .8 4.9 1.7 7.2 2.4c13.6 4.6 24 8.1 35.1 8.5c8.6 .3 16.5-1.6 21.4-4.7c4.1-2.5 6-5.5 5.9-10.5c0-2.9-.8-5-5.9-8.2c-6.3-4-15.4-6.9-28-10.7l-1.7-.5c-10.9-3.3-24.6-7.4-35.6-14c-12.7-7.7-24.6-20.5-24.7-40.7c-.1-21.1 11.8-35.7 25.8-43.9c6.9-4.1 14.5-6.8 22.2-8.5V136c0-11 9-20 20-20s20 9 20 20z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'sales-inquery/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'sales-inquery/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // quotation
            enable: false,
            routerName: 'quotation',
            routerLink: 'quotation',
            icon: 'M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm48 160H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zM96 336c0-8.8 7.2-16 16-16H464c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zM376 160h80c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H376c-13.3 0-24-10.7-24-24V184c0-13.3 10.7-24 24-24z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'quotation/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                // {
                //     enable: false,
                //     routerName: 'create',
                //     routerLink: 'quotation/create',
                //     icon: this.childNavIcons.create,
                //     pre_navBreackPoint: false,
                //     post_navBreackPoint: false,
                // },
                {
                    enable: false,
                    routerName: 'approve',
                    routerLink: 'quotation/approve',
                    icon: this.childNavIcons.approve,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // sales-order
            enable: false,
            routerName: 'sales order',
            routerLink: 'sales-order',
            icon: 'M312 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'sales-order/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'sales-order/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'search-item',
                    routerLink: 'sales-order/search-sales-order-item',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'approve',
                    routerLink: 'sales-order/approve',
                    icon: this.childNavIcons.approve,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'process-artwork',
                    routerLink: 'sales-order/process-artwork',
                    icon: this.childNavIcons.artwork,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'add-bom',
                    routerLink: 'sales-order/add-bom',
                    icon: this.childNavIcons.inventory,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // NPD
            enable: false,
            routerName: 'npd',
            routerLink: 'npd',
            icon: 'M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm16 80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 48-48 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l48 0 0 48c0 8.8 7.2 16 16 16s16-7.2 16-16l0-48 48 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-48 0 0-48z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'npd/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'npd/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'approve',
                    routerLink: 'npd/approve',
                    icon: this.childNavIcons.approve,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'bom',
                    routerLink: 'npd/bom',
                    icon: this.childNavIcons.inventory,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // artwork
            enable: false,
            routerName: 'artwork',
            routerLink: 'artwork',
            icon: 'M512 256c0 .9 0 1.8 0 2.7c-.4 36.5-33.6 61.3-70.1 61.3H344c-26.5 0-48 21.5-48 48c0 3.4 .4 6.7 1 9.9c2.1 10.2 6.5 20 10.8 29.9c6.1 13.8 12.1 27.5 12.1 42c0 31.8-21.6 60.7-53.4 62c-3.5 .1-7 .2-10.6 .2C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'artwork/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'artwork/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // packing
            enable: false,
            routerName: 'packing',
            routerLink: 'packing',
            icon: 'M256 48c0-26.5 21.5-48 48-48H592c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H381.3c1.8-5 2.7-10.4 2.7-16V253.3c18.6-6.6 32-24.4 32-45.3V176c0-26.5-21.5-48-48-48H256V48zM571.3 347.3c6.2-6.2 6.2-16.4 0-22.6l-64-64c-6.2-6.2-16.4-6.2-22.6 0l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 310.6V432c0 8.8 7.2 16 16 16s16-7.2 16-16V310.6l36.7 36.7c6.2 6.2 16.4 6.2 22.6 0zM0 176c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16V176zm352 80V480c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V256H352zM144 320c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H144z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'packing/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'packing/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // dispatch
            enable: false,
            routerName: 'dispatch',
            routerLink: 'dispatch',
            icon: 'M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'dispatch/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'dispatch/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'return',
                    routerLink: 'dispatch/return',
                    icon: this.childNavIcons.return,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
        { // invoice
            enable: false,
            routerName: 'invoice',
            routerLink: 'invoice',
            icon: 'M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0 0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z',
            pre_navBreackPoint: false,
            post_navBreackPoint: false,
            childrenRoutes: [
                {
                    enable: false,
                    routerName: 'search',
                    routerLink: 'invoice/search',
                    icon: this.childNavIcons.search,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
                {
                    enable: false,
                    routerName: 'create',
                    routerLink: 'invoice/create',
                    icon: this.childNavIcons.create,
                    pre_navBreackPoint: false,
                    post_navBreackPoint: false,
                },
            ]
        },
    ]

    getNavigationItems() {
        return this.navigationItems
    }

    filterAllowedRoutes() {
        this.updateEnableProperty(this.navigationItems)
        this.updateEnableProperty(this.uiUrlPaths)
    }

    updateEnableProperty(arrayToUpdate: any[]) {
        let allowedPermissions: any[] = this.sessionStorageService.getPermissions() ?? [];

        for (const item of arrayToUpdate) {
            for (const childRoute of item.childrenRoutes) {
                if (allowedPermissions.some(route => route.path === childRoute.routerLink)) {
                    childRoute.enable = true;
                    item.enable = true; // Set main part enable to true if any child route is enabled
                }
            }
        }

        arrayToUpdate[0].enable = true // Enable dashboard
    }

    activateAllRoutes() {
        // Update enable property for child routes and main part
        for (const item of this.navigationItems) {
            item.enable = true;
            for (const childRoute of item.childrenRoutes) {
                childRoute.enable = true;
            }
        }

        for (const item of this.uiUrlPaths) {
            item.enable = true;
            for (const childRoute of item.childrenRoutes) {
                childRoute.enable = true;
            }
        }
    }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, CostingGuard, LoginGuard } from './auth-guard.service';
import { CustomerResolver, InventoryResolver, PurchaseOrderResolver, ServerHelthResolver, SupplierResolver } from './resolvers';
import { RouteGuard } from './route-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // Set route
  {
    path: 'login',
    loadComponent: () => import('./core/index').then(com => com.LoginComponent),
    canActivate: [LoginGuard]
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./core/index').then(com => com.PasswordResetComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'unauthorized',
    loadComponent: () => import('./core/index').then(com => com.UnauthorizedComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'connection-lost',
    loadComponent: () => import('./core/index').then(com => com.ConnectionLostComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./core/index').then(com => com.DashboardComponent),
    canActivate: [RouteGuard, AuthGuard],
    resolve: { serverHealth: ServerHelthResolver }
  },
  {
    path: 'hello-world',
    loadComponent: () => import('./core/index').then(com => com.HelloWorldComponent),
    canActivate: [AuthGuard],
    resolve: {}
  },
  { // inventory
    path: '',
    loadChildren: () => import('./core/components/inventory/inventory.routes').then(routes => routes.InventoryRoutes),
    canActivate: [RouteGuard, AuthGuard],
    resolve: { data: InventoryResolver, serverHealth: ServerHelthResolver }
  },
  { // supplier
    path: '',
    loadChildren: () => import('./core/components/supplier/supplier.routes').then(routes => routes.SupplierRoutes),
    canActivate: [RouteGuard, AuthGuard],
    resolve: { data: SupplierResolver, serverHealth: ServerHelthResolver }
  },
  { // customer
    path: '',
    loadChildren: () => import('./core/components/customer/customer.routes').then(routes => routes.CustomerRoutes),
    canActivate: [RouteGuard, AuthGuard],
    resolve: { data: CustomerResolver, serverHealth: ServerHelthResolver }
  },
  { // purchase-order
    path: '',
    loadChildren: () => import('./core/components/purchase-order/po.routes').then(routes => routes.PORoutes),
    canActivate: [RouteGuard, AuthGuard],
    resolve: { data: PurchaseOrderResolver, serverHealth: ServerHelthResolver }
  },
  { // grn
    path: '',
    loadChildren: () => import('./core/components/grn/grn.routes').then(routes => routes.GRNRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // grn
    path: '',
    loadChildren: () => import('./core/components/mrn/mrn.routes').then(routes => routes.MRNRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // gin
    path: '',
    loadChildren: () => import('./core/components/gin/gin.routes').then(routes => routes.GINRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // employee 
    path: '',
    loadChildren: () => import('./core/components/employee/employee.routes').then(routes => routes.EmployeeRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // employee-category
    path: '',
    loadChildren: () => import('./core/components/employee-category/employee.routes').then(routes => routes.EmployeeCategoryRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // sales-inquery
    path: '',
    loadChildren: () => import('./core/components/sales-inquery/sales-inquery.routes').then(routes => routes.SalesInqueryRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // product-development
    path: '',
    loadChildren: () => import('./core/components/product-development/product-development.routes').then(routes => routes.ProductDevelopmentRoutes),
    canActivate: [RouteGuard, AuthGuard, CostingGuard]
  },
  { // quotation
    path: '',
    loadChildren: () => import('./core/components/quotation/quotation.routes').then(routes => routes.QuotationRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // sales-order
    path: '',
    loadChildren: () => import('./core/components/sales-order/sales-order.routes').then(routes => routes.SalesOrderRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // sales-order
    path: '',
    loadChildren: () => import('./core/components/npd/npd.routes').then(routes => routes.NpdRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // packing
    path: '',
    loadChildren: () => import('./core/components/packing/packing.routes').then(routes => routes.PackingRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // dispatch
    path: '',
    loadChildren: () => import('./core/components/dispatch/dispatch.routes').then(routes => routes.DispatchRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // invoice
    path: '',
    loadChildren: () => import('./core/components/invoice/invoice.routes').then(routes => routes.InvoiceRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  { // artwork
    path: '',
    loadChildren: () => import('./core/components/artwork/artwork.routes').then(routes => routes.ArtworkRoutes),
    canActivate: [RouteGuard, AuthGuard]
  },
  // { // reports
  //   path: '',
  //   loadChildren: () => import('./core/components/reports/reports.routes').then(routes => routes.ReportRoutes),
  //   canActivate: [RouteGuard, AuthGuard]
  // },
  {
    path: 'reports',
    loadComponent: () => import('./core/components/reports/daily-sales-report/daily-sales-report.component').then(com => com.DailySalesReportComponent),
    canActivate: [RouteGuard, AuthGuard],
  },
  /// pdf routes
  // {
  //   path: 'npd-print',
  //   loadChildren: () => import('./core/components/pdf-templates/npd-pdf/npd-pdf.routes').then(routes=>routes.NpdPdfRoute),
  //   canActivate: [AuthGuard]
  // }, 
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthAPIService } from './core/services';
import { SessionStorageService } from './core/util/session-storage.service';
import { NavArray } from './core/components/aa_layouts/nav-bar/nav-array';

@Injectable({
    providedIn: 'root'
})
export class RouteGuard implements CanActivate {

    // requestedPath = ""

    constructor(
        private router: Router,
        private sessionStorageService: SessionStorageService,
        private navArray: NavArray,
    ) {
        this.createRoutesArray()
    }

    routesArray: any[] = []

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const parentPath = state.url.split('/')[1]; // Get the main path 

        const requestedPath = route.routeConfig?.path ?? ""
        const requestedChildPath = route.firstChild?.routeConfig?.path ?? ""

        // Check if the requested path or child route is in the allowedRoutes array
        if (requestedPath && this.routesArray.includes(requestedPath)) {
            return true; // Allow access to the requested path
        } else if (requestedChildPath && this.routesArray.includes(requestedChildPath)) {
            return true; // Allow access to the requested child path
        } else if (requestedPath.includes("search") || requestedChildPath.includes("search")) {
            try {
                // The default direction is search, 
                // If search is blocked from user then user will direct to next possible route
                // But this will work only for search page only
                let filteredChildRoutes = this.filterChildRoutesForParent(parentPath)

                if (filteredChildRoutes.length > 0) {
                    this.router.navigate([filteredChildRoutes[1]]); // 0th index is parent route so we take the 2nd item from array
                    return false // Prevent the current route from activating
                } else {
                    this.router.navigate(['/unauthorized']); // Redirect to an unauthorized page
                    return false
                }
            } catch (error) {
                this.router.navigate(['/unauthorized']); // Redirect to an unauthorized page
                return false
            }

        }

        // Redirect to a different route or show an error message
        this.router.navigate(['/unauthorized']); // Redirect to an unauthorized page
        return false;
    }

    createRoutesArray() {
        let navItems = this.navArray.getUiUrlPaths()
        for (let item of navItems) {
            if (item.enable) {
                this.routesArray.push(item.routerLink)
                for (let subRoute of item.childrenRoutes) {
                    if (subRoute.enable) {
                        this.routesArray.push(subRoute.routerLink)
                    }
                }
            }
        }
    }

    filterChildRoutesForParent(parentRoute: string): string[] {
        try {
            return this.routesArray.filter(item => item.includes(parentRoute))
        } catch (error) {
            return []
        }
    }
}
